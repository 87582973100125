<template>
  <div>
    <!-- #PCR-2504 : RGAA audit#1 (Vue) > Présentation info (10) > CSS (10.1) -->
    <!-- height doit être calculé depuis le style css 'iframe'-->
    <div class="fr-container-title" id="title-paiement-impot-pro">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Paiement des impôts locaux PRO</h1>
      <h2>En utilisant la référence de mon avis</h2>
    </div>

    <iframe
      id="outerlink"
      :src="uri"
      frameborder="0"
      :class="fullSize ? 'fullSize' : ''"
      ref="iframe"
    ></iframe>
    <p class="text-center text-gray mt-3 pb-4">
      Ces informations sont données à titre indicatif et ne sont pas
      nécessairement exhaustives.
    </p>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

const fullSizeEServiceList = [
  'DECLARER_CVAE',
  'DECLARER_IS',
  'DECLARER_RESULTAT',
  'DECLARER_REVENUS_DE_CAPITAUX_MOBILIERS',
  'DECLARER_TGAP',
  'DECLARER_TS',
  'DECLARER_TVA',
  'DECLARER_TVVI',
  'DECLARER_TIC'
];
export default {
  data() {
    return {
      fullSize: false,
      uri: undefined
    };
  },
  computed: {
    ...mapGetters(['getRouteData'])
  },
  methods: {
    isFullSize() {
      let urlParams = new URLSearchParams(window.location.href);
      if (urlParams) {
        for (var pair of urlParams.entries()) {
          // Avec nos urls URLSearchParams considère que le premier paramètre est la concaténation
          // du début de l'url et du paramètre param.
          // Dans ce cas là si eservice est le premier paramètre
          // urlParams.get('eservice')n'est pas suffisant pour récupérer la valeur du paramètre eservice
          // C'est pourquoi on itère sur tous les couples clé/valeur des paramètres pour trouver celui
          // qui contient eservice
          if (pair[0].includes('eservice')) {
            if (this.isInFullSizeList(pair[1])) {
              return true;
            }
          }
        }
      }
      return false;
    },

    isInFullSizeList(eserviceValue) {
      return fullSizeEServiceList.some((element) => {
        return element.toLowerCase() === eserviceValue.toLowerCase();
      });
    },
    ...mapActions(['fetchIndicateurs'])
  },

  mounted() {
    //On vient du CDS
    if (this.$route.query && this.$route.query.url) {
      this.uri = this.$route.query.url;
    } else {
      this.uri =
        this.getRouteData && this.getRouteData.uri
          ? this.getRouteData.uri
          : process.env.VUE_APP_IMPOT_LOC_PRO_IFRAME;
    }

    window.iframe = this.$refs.iframe;
    if (this.isFullSize()) {
      this.fullSize = true;
      this.$nextTick().then(() => {
        this.$refs.iframe.scrollIntoView({ behavior: 'smooth' });
      });
    }
    let libelle = localStorage.getItem('libelleTeleservice');
    let title = document.getElementById('title-paiement-impot-pro');
    if (libelle === 'Paiement des impôts locaux PRO') {
      title.style.display = 'block';
    } else {
      title.style.display = 'none';
    }
    this.$nextTick().then(() => {
      window.scrollTo(0, 0);
    });
  },
  unmounted() {
    if (localStorage.getItem('libelleTeleservice'))
      localStorage.removeItem('libelleTeleservice');

    this.fetchIndicateurs(this.$store?.state.compte.siren);
  }
};
</script>
<style lang="scss" scoped>
iframe {
  min-width: 100%;
  height: 500vh;
  width: 100%;
}

.fullSize {
  height: 100vh !important;
}
</style>
